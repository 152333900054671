import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query LakotaAcHeaderNavQuery {
    craft {
      globalSet(handle: "header", site: "lakotaAc") {
        ... on Craft_header_GlobalSet {
          name
          navHeading
          navCopy
          headerButton {
            url
            text
          }
          navCampaigns {
            ... on Craft_navCampaigns_campaign_BlockType {
              uid
              enabled
              campaign {
                ... on Craft_issue_issue_Entry {
                  enabled
                  heading0
                  slug
                }
              }
            }
          }
          secondaryNav {
            ... on Craft_secondaryNav_navLink_BlockType {
              uid
              enabled
              navLink {
                url
              }
              linkText
            }
          }
        }
      }
    }
  }
`;

const dataResolver = ({ craft }) => {
  const { navHeading, navCopy, navCampaigns, secondaryNav, headerButton } =
    craft.globalSet;
  return {
    heading: navHeading,
    copy: navCopy,
    campaigns: navCampaigns
      .filter(c => c.campaign[0])
      .map(campaign => {
        return {
          uid: campaign.campaign[0].uid,
          path: `/${campaign.campaign[0].slug}`,
          url: `/${campaign.campaign[0].slug}`,
          heading: campaign.campaign[0].heading0,
        };
      }),
    secondaryNav: secondaryNav.map(link => {
      return {
        link: link.navLink?.url,
        text: link.linkText,
      };
    }),
    button: headerButton,
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  return dataResolver(response);
};

export default useData;
